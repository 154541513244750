body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  background-color: #fff;
}

.main-container {
  padding: 0 120px;
}

@media (max-width: 992px) {
  .main-container {
    padding: 0 80px;
  }
}

@media (max-width: 756px) {
  .main-container {
    padding: 0 20px;
  }
}
